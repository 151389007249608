import { Injectable } from '@angular/core';
import {LocationMetadata, NoteMetadata, PostClassFormData, TimingMetadata, TypeMetadata} from './models/post-class-form-data';
import {AngularFirestore, AngularFirestoreCollection} from '@angular/fire/firestore';
import {ManageDetailsService} from './manage/manage-details.service';
import {Router} from '@angular/router';
import * as firebase from 'firebase/app';
import * as geofirex from 'geofirex';
import {BehaviorSubject, Subject} from 'rxjs';
import {Class} from './models/class';

@Injectable({
  providedIn: 'root'
})
export class ClassService {
  private formData: PostClassFormData;
  private isTypeMetadataValid: boolean;
  private isTimingMetadataValid: boolean;
  private isLocationMetadataValid: boolean;
  private geo: any;
  private klass: Class;
  private savedDocSubject: Subject<string>;
  private classesCollection: AngularFirestoreCollection;

  constructor(private angularFireStore: AngularFirestore,
              private manageDetailsService: ManageDetailsService,
              private router: Router) {

    this.formData = new PostClassFormData();
    this.klass = <Class>{};
    this.geo = geofirex.init(firebase);
    this.isTypeMetadataValid = false;
    this.isLocationMetadataValid = false;
    this.isTimingMetadataValid = false;
    this.savedDocSubject = new BehaviorSubject<string>(null);
    this.classesCollection = this.angularFireStore.collection('classes');
  }

  public typeMetadataValid() {
    return this.isTypeMetadataValid;
  }

  public locationMetadataValid() {
    return this.isLocationMetadataValid;
  }

  public timingMetadataValid() {
    return this.isTimingMetadataValid;
  }

  setTypeMetadata(metadata: TypeMetadata, selectedClass?) {
    if (selectedClass) {
      const selectedStyle = selectedClass.styles.find( style => style.name === metadata.style);
      metadata.certRequiredForStyle = selectedStyle.certificate;
    }

    this.isTypeMetadataValid = true;
    this.formData.typeMetadata = metadata;
    this.klass.type = {
      level: metadata.level,
      style: metadata.style,
      speciality: metadata.speciality
    };
  }

  getTypeMetadata() {
    return this.formData.typeMetadata;
  }

  setTimingMetadata(metadata: TimingMetadata) {
    metadata.dateTime = this.combineDateAndTime(metadata.date, metadata.time);
    this.isTimingMetadataValid = true;
    this.formData.timingMetadata = metadata;
    this.klass.duration = metadata.spanInMinutes;
    this.klass.radius = metadata.spanInWeeks;
    this.klass.dateTime = metadata.dateTime;
  }

  getTimingMetadata() {
    return this.formData.timingMetadata;
  }

  setLocationMetadata(metadata: LocationMetadata) {
    this.isLocationMetadataValid = true;
    this.formData.locationMetadata = metadata;
    this.klass.address = metadata.address;
    this.klass.postCode = metadata.postCode;
    this.klass.townOrCity = metadata.townOrCity;
  }

  getLocationMetadata() {
    return this.formData.locationMetadata;
  }

  setNoteMetadata(metadata: NoteMetadata) {
    this.formData.noteMetadata = metadata;
    this.klass.note = metadata.note;
  }

  getNoteMetadata() {
    return this.formData.noteMetadata;
  }

  postClass() {
    this.manageDetailsService.getAuth().subscribe(user => {
      if (user) {
        const data = Object.assign(this.formData.typeMetadata,
                                 this.formData.timingMetadata,
                                 this.formData.locationMetadata,
                                 this.formData.noteMetadata );

        this.classesCollection.add({
          type: {
            level: data.level,
            speciality: data.speciality,
            style: data.style
          },
          activity: data.type,
          certRequiredForStyle: data.certRequiredForStyle,
          radius: data.spanInWeeks,
          location: this.geo.point(data.latitude, data.longitude).data,
          dateTime: data.dateTime,
          postCode: data.postCode,
          duration: data.spanInMinutes,
          address: data.address,
          townOrCity: data.townOrCity,
          userId: user.uid,
          submissions: []
        }).then((doc) => {
          this.savedDocSubject.next(doc.id);
          this.router.navigate(['/post-class', 'success']);
        });
      }
    });
  }

  coverClass(classID) {
    const classRef = this.classesCollection.doc(`${classID}`);

    this.manageDetailsService.getAuth().subscribe(user => {
      this.classesCollection.doc(`${classID}`).get().subscribe(doc => {
        if (doc.exists) {
          classRef.update({submissions: firebase.firestore.FieldValue.arrayUnion(user.uid)})
            .then(() => this.router.navigate(['/find-class', 'success']));
        }
      });
    });
  }

  acceptCover(id, user) {
    return this.classesCollection.doc(`${id}`).update({ covered: true, coveredBy: user });
  }

  getClass(id) {
    return this.classesCollection.doc(`${id}`).valueChanges();
  }

  getSavedDoc() {
    return this.savedDocSubject.asObservable();
  }

  setClassId(id: string) {
    this.klass.id = id;
  }

   get formDataClass() {
    return this.klass;
   }
  public splitDateTime(klass: any) {
    const dateTime = klass.dateTime.toDate();
    const timeString = dateTime;
    const year = dateTime.getFullYear();
    const month = dateTime.getMonth() + 1;
    const day = dateTime.getDate();
    const dateString = '' + year + '-' + month + '-' + day;
    klass.time = new Date(timeString);
    klass.date = new Date(dateString);
    return klass;
  }

  updateClass() {
    this.classesCollection.doc(this.klass.id).update(this.klass)
      .then( () => {
        this.formData = new PostClassFormData();
        this.router.navigate(['/post-class', 'success']);
      });
  }

  deleteClass(id: string) {
    this.classesCollection.doc(id).delete()
      .then(() => this.router.navigate(['/find-class']));
  }
  private combineDateAndTime = function(date, time) {
    const timeString = time.getHours() + ':' + time.getMinutes() + ':00';

    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const dateString = '' + year + '-' + month + '-' + day;
    return new Date(dateString + ' ' + timeString);
  };
}
