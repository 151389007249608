import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { AuthService } from '../core/auth.service';
import { SettingsService } from '../settings/settings.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  isUser: any;
  public menuOpen = false;
  pSlug: any;
  home: any;
  labels: any;
  profile: any;

  constructor(
    private renderer: Renderer2,
    private settingsService: SettingsService
  ) {
    this.settingsService.getUserId()
      .subscribe(
        user => {
          if(user) {
            this.isUser = true;
            
            this.settingsService.profileSlug(user.uid)
              .subscribe(
                profile => {
                  if(profile) {
                    this.profile = profile;
                    this.pSlug = this.settingsService.buildProfileSlug(profile);
                  }
                }
              );
          }
          else {
            this.isUser = false;
          }
        }
      );
      this.settingsService.getHomeSettings()
        .subscribe(
          home => {
            this.home = home;
          },
          errors => console.log(errors)
        );
      this.settingsService.getLabelsSettings()
        .subscribe(
          labels => {
            this.labels = labels;
          },
          errors => console.log(errors)
        );
  }

  ngOnInit() {
    this.renderer.addClass(document.body, "home-page");
  }

  openMenu() {
    if(this.menuOpen == false) {
      this.menuOpen = true
    } else {
      this.menuOpen = false
    }
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, "home-page");
  }

}
