import { Component, OnInit } from '@angular/core';

import { SettingsService } from '../../settings/settings.service';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss']
})
export class FaqsComponent implements OnInit {
  public faqs: any;

  constructor(private settingsService: SettingsService) {
    
  }

  ngOnInit() {
    this.get();
  }

  get() {
    this.settingsService.getFAQsSettings()
      .subscribe(
        faqs => this.faqs = faqs,
        errors => console.log(errors)
      );
  }

}
