import { Component, OnInit } from '@angular/core';

import { SettingsService } from '../../settings/settings.service';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit {
  public page: any;

  constructor(private settingsService: SettingsService) {
    this.settingsService.getTermsSettings()
        .subscribe(
          page => {
            this.page = page;
          },
          errors => console.log(errors)
        );
  }

  ngOnInit() {
  }

}
