import { Component, OnInit, Input } from '@angular/core';

import { SettingsService } from '../../settings.service';

@Component({
  selector: 'app-single-cert',
  templateUrl: './single-cert.component.html',
  styleUrls: ['./single-cert.component.scss']
})
export class SingleCertComponent implements OnInit {
  @Input() certificate: any;

  user: any;

  constructor(private settingsService: SettingsService) { }

  ngOnInit() {
    if(this.certificate.userId) {
      this.usersName(this.certificate.userId);
    }
  }

  usersName(id) {
    this.settingsService.getUsersName(id)
      .subscribe(
        user => this.user = user,
        errors => console.log(errors)
      )
  }

  approve() {
    let userId = this.certificate.userId;
    let certId = this.certificate.id;
    let style = this.certificate.style;
    let type = this.certificate.type;

    this.settingsService.approveCertificate(userId, type, style, certId);
  }

  unapprove() {
    let userId = this.certificate.userId;
    let certId = this.certificate.id;
    let style = this.certificate.style;
    let type = this.certificate.type;
    
    this.settingsService.unapproveCertificate(userId, type, style, certId);
  }

}
