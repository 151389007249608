import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { SettingsService } from '../../settings.service';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs-page.component.html',
  styleUrls: ['./faqs-page.component.scss']
})
export class FaqsPageComponent implements OnInit {
  form = this.fb.group({
    title: ['', [Validators.required]],
    content: ['', [Validators.required] ]
  });

  faqs: any;

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '25rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    uploadUrl: 'v1/images', // if needed
    customClasses: [ // optional
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ]
  };

  constructor(
    public fb: FormBuilder,
    private settingsService: SettingsService
  ) {}

  ngOnInit() {
    this.get();
  }

  get() {
    this.settingsService.getFAQsSettings()
      .subscribe(
        faqs => this.faqs = faqs,
        errors => console.log(errors)
      );
  }

  add() {
    if (this.form.valid) {
      this.settingsService.addFAQsSettings(this.form.value);
    }
  }

  delete(faq) {
    this.settingsService.deleteFAQsSettings(faq);
  }

}
