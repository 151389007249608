import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { map, take } from 'rxjs/operators';
import { AuthService } from "./auth.service";
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class AdminAuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService
  ) {
  }

  canActivate() {
    return this.authService.getAdmin().pipe(
      map(user => {
        if (!user) {
          // noinspection JSIgnoredPromiseFromCall
          this.router.navigate(['/sign-in']);
          return false;
        }
        return true;
      }),
      take(1),
    );
  }
}
