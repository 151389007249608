import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';

import { SettingsService } from '../settings.service';

@Component({
  selector: 'app-classes',
  templateUrl: './classes.component.html',
  styleUrls: ['./classes.component.scss']
})
export class SettingsClassesComponent implements OnInit {
  public levelsArr = [];
  public stylesArr = [];
  public errors = [];
  public specialitiesArr = [];
  public classes: Object;
  public update: boolean;
  updateId: string;

  form = this.fb.group({
    type: [''],
    levels: [[]],
    styles: [[]],
    specialities: [[]],
  });


  constructor(
    public fb: FormBuilder,
    private settingsService: SettingsService
    ) { }

  ngOnInit() {
    // Retrieve initial classes
    this.getClasses()
  }

  getClasses() {
    this.settingsService.getClasses()
      .subscribe(
        classes => { this.classes = classes; console.log(classes) },
        errors => console.log(errors)
      )
  }

  /* Generate array for saving later */
  levels(item) {
    this.levelsArr.push(item);
  }

  /* Generate array for saving later */
  styles(item, cert) {
    this.stylesArr.push({ name: item, certificate: cert });
  }

  /* Generate array for saving later */
  specialities(item) {
    this.specialitiesArr.push(item);
  }

  patch() {
    // Add array
    this.form.controls["levels"].patchValue(this.levelsArr);

    // Add array
    this.form.controls["styles"].patchValue(this.stylesArr);

    // Add array
    this.form.controls["specialities"].patchValue(this.specialitiesArr);
  }

  delete(item) {
    this.settingsService.deleteClass(item.id);
  }

  cancel(){
    // Reset data and update for fresh entry
    this.form.reset();
    this.levelsArr = [];
    this.stylesArr = [];
    this.specialitiesArr = [];
    this.update = false;
    this.updateId = null;
    this.errors = [];
  }
  
  edit(item) {
    // Change template text
    this.update = true;
    this.updateId = item.id;
    this.errors = [];

    this.levelsArr = item.levels;
    this.stylesArr = item.styles;
    this.specialitiesArr = item.specialities;

    // Manual Patch
    this.form.controls["type"].patchValue(item.type);

    this.patch();
  }

  save() {
    if(this.update == true && this.updateId) {
      // Update class
      this.settingsService.updateClass(this.form.value, this.updateId)
        .then(() => {
          // Reset data and update for fresh entry
          this.form.reset();
          this.levelsArr = [];
          this.stylesArr = [];
          this.specialitiesArr = [];
          this.update = false;
          this.updateId = null;
          this.errors = [];
        });
    } else {
      this.patch();

      if(this.form.controls["type"].value != "" && this.levelsArr.length > 0 && this.stylesArr.length > 0 && this.specialitiesArr.length > 0) {
        // Save new class
        this.settingsService.saveNewClass(this.form.value);
      } else {
        // Scroll to the top
        window.scroll(0,0);

        if(this.form.controls["type"].value == "") {
          this.errors.push("Please fill in type");
        }
        if(this.levelsArr.length == 0) {
          this.errors.push("Please add a level");
        }
        if(this.stylesArr.length == 0) {
          this.errors.push("Please add a style");
        }
        if(this.specialitiesArr.length == 0) {
          this.errors.push("Please add a speciality");
        }
      }
    }
  }

}
