import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { SettingsService } from '../settings/settings.service';

import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {
  profile: any;
  classes: any;

  constructor(
    private renderer: Renderer2,
    private settingsService: SettingsService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.renderer.addClass(document.body, "profile-page");
    this.renderer.addClass(document.body, "reverse-bg");

    // Get slug from URL
    this.route.params.subscribe( params => {
      this.getUserProfile(params['slug']);
      this.getUserClasses(params['slug']);
    });
  }

  getUserProfile(slug) {
    // Pass Slug
    this.settingsService.getProfile(slug)
      .subscribe(
        profile => this.profile = profile,
        errors => console.log(errors)
      )
  }

  getUserClasses(slug) {
    // Pass Slug
    this.settingsService.getClassesBySlug(slug)
      .subscribe(
        classes => this.classes = classes.filter(x => x.verified == true),
        errors => console.log(errors)
      );
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, "profile-page");
    this.renderer.removeClass(document.body, "reverse-bg");
  }

}
