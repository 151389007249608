import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {SearchService} from '../../search.service';
import {SettingsService} from '../../settings/settings.service';
import {UploadService} from '../../upload.service';
import {ClassService} from '../../class.service';
import {ManageDetailsService} from '../../manage/manage-details.service';
import {LocationMetadata, NoteMetadata, TimingMetadata, TypeMetadata} from '../../models/post-class-form-data';
import {Class} from '../../models/class';

@Component({
  selector: 'app-class-details',
  templateUrl: './class-details.component.html',
  styleUrls: ['./class-details.component.scss']
})
export class ClassDetailsComponent implements OnInit {
  currentUser: any;
  coveringErrorMessage: string;
  klass: any;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private searchService: SearchService,
              private uploadService: UploadService,
              private classService: ClassService,
              private detailsService: ManageDetailsService,
              private settingsService: SettingsService) { }

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.detailsService.getAuth().subscribe( user => {
        if (user) {
          // Cross check for submissions
          this.currentUser = user.uid;

          this.detailsService.getProfileDetails(user.uid).subscribe( (profile: any) => {
            this.searchService.getClass(params.get('id')).subscribe(  (klass: Class ) => {
              if (!klass) {
                return;
              }

              klass.canNotBeCovered = true;
              this.searchService.getUserOfClass(klass.userId).subscribe( owner => {
                if (owner) {
                  klass.id = params.get('id');
                  klass.user = {
                    name: owner.fname + ' ' + owner.lname,
                    slug: this.settingsService.buildProfileSlug(owner),
                    id: owner.userId };
                  this.checkCoveringStatus(klass, profile);
                  this.klass = klass;
                  this.uploadService.getDownloadURLFromRef(owner.picture).subscribe( url => {
                    this.klass.user.picture = url;
                  });
                }
              });
            });
          });
        }
      });
    });
  }

  coverClass() {
    this.classService.coverClass(this.klass.id);
  }

  editClass() {
    this.classService.setClassId(this.klass.id);
    this.classService.setTypeMetadata(new TypeMetadata({
      type: this.klass.activity,
      speciality: this.klass.type.speciality,
      level: this.klass.type.level,
      style: this.klass.type.style
    }));

    this.classService.setLocationMetadata(new LocationMetadata(this.klass));

    this.classService.setNoteMetadata(new NoteMetadata(this.klass));
    this.classService.setTimingMetadata(new TimingMetadata(this.classService.splitDateTime(this.klass)));
    this.router.navigate(['/post-class']);
  }

  deleteClass() {
    if(confirm("Are you sure to delete this class?")) {
      this.classService.deleteClass(this.klass.id);
    }
  }

  checkCoveringStatus(klass, profile) {
    klass.canNotBeCovered = true;
    klass.submissions = klass.submissions || [];
    if (klass.user.id === profile.userId) {
      this.coveringErrorMessage = 'Can\'t cover your own class';
      return;
    } else {
      klass.canNotBeCovered = false;
    }

    if (klass.submissions) {
      klass.canNotBeCovered = true;
      if (klass.submissions.includes(profile.userId)) {
        this.coveringErrorMessage = 'Can\'t cover same class twice';
        return;
      } else {
        klass.canNotBeCovered = false;
      }

      if (klass.certRequiredForStyle === true) {
        this.searchService.verifiedStyles.subscribe( styles => {
          if (styles) {
            if (styles.includes(klass.type.style)) {
              klass.canNotBeCovered = false;
            } else {
              klass.canNotBeCovered = true;
              this.coveringErrorMessage = 'Requires valid certificate';
            }
          } else {
            this.searchService.getVerifiedActivitiesAndStyles(profile);
          }
        });
      } else {
        klass.canNotBeCovered = false;
      }
    }

  }
}
