import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { SettingsService } from '../../settings.service';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyPagesComponent implements OnInit {
  form = this.fb.group({
    title: ['', [Validators.required]],
    content: ['', [Validators.required] ]
  });

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '25rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    uploadUrl: 'v1/images', // if needed
    customClasses: [ // optional
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };

  constructor(
    public fb: FormBuilder,
    private settingsService: SettingsService
    ) { }

  ngOnInit() {
    this.settingsService.getPrivacySettings()
      .subscribe(
        privacy => this.form.patchValue(privacy),
        errors => console.log(errors)
      )
  }

  save() {
    if(this.form.valid) {
      this.settingsService.savePrivacySettings(this.form.value);
    }
  }

}
