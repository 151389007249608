import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ClassService} from '../../class.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-timing-metadata',
  templateUrl: './timing-metadata.component.html',
  styleUrls: ['./timing-metadata.component.scss']
})
export class TimingMetadataComponent implements OnInit, AfterViewInit {

  @ViewChild('range') range: ElementRef;

  form: FormGroup;

  constructor(private dataService: ClassService,
              private formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({
      date: ['', Validators.required],
      time: ['', Validators.required],
      spanInMinutes: ['', Validators.required],
      spanInWeeks: ['', Validators.required],
    });
}

  ngOnInit() {
    this.initializeForm();
  }

  ngAfterViewInit(): void {
    this.changeRangeText();
  }

  initializeForm() {
    this.form.patchValue(this.dataService.getTimingMetadata());
  }

    dateFilter(d: Date): boolean {
    const now = new Date();
    return now.getDate() <= d.getDate();
  }

  saveData() {
    if (this.form.valid) {
      this.dataService.setTimingMetadata(this.form.value);
    }
  }

  changeRangeText() {
    let element, width, ctimeHr, ctimeMin, ctimeHrText, ctimeMinSpace, ctimeMinText, newPoint, newPlace, offset;
    element = this.range.nativeElement;
    width = element.offsetWidth;
    newPoint = (element.value - element.getAttribute('min')) / (element.getAttribute('max') - element.getAttribute('min'));
    offset = -1;
    if (newPoint < 0) {newPlace = 0; } else if (newPoint > 1) {newPlace = width; } else {newPlace = width * newPoint + offset; offset -= newPoint; }
    element.nextElementSibling.style.left = newPlace + "px";
    element.nextElementSibling.style.marginLeft = offset + '%';
    ctimeHr = Math.floor(element.value / 60);
    ctimeMin = element.value % 60;
    if (ctimeHr == 1) {ctimeHrText = ctimeHr + ' hour'; } else if (ctimeHr < 1) {ctimeHrText = ''; } else {ctimeHrText = ctimeHr + ' hours'; }
    if (ctimeMin == 1) {ctimeMinText = ctimeMin + ' min'; } else if (ctimeMin < 1) {ctimeMinText = ''; } else {ctimeMinText = ctimeMin + ' mins'; }
    if (ctimeMinText != '') {ctimeMinSpace = ' '; } else {ctimeMinSpace = ''; }
    element.nextElementSibling.textContent = (ctimeHrText + ctimeMinSpace + ctimeMinText);
  }
}
