import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({providedIn: 'root'})
export class UscreenService {
    constructor(
        private httpClient: HttpClient
        ) { }

    getCategories() {
        // Headers for Uscreen
        let headers = {
            headers: new HttpHeaders({
            'Content-Type':  'application/json',
            'X-Store-Token': '1uxfEbdAqpEsOw=='
            })
        };

        return this.httpClient.get('https://uscreen.io/api/v1/categories', headers).toPromise();
    }
}