import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';

// Bootstrap
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from './login/login.component';
import { ManageDetailsComponent } from './manage/manage-details.component';

// Firebase
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from '../environments/environment';

// Festivals
import { NavbarComponent } from './navbar/navbar.component';

// Editor
import { AngularEditorModule } from '@kolkov/angular-editor';

// DateTimePicker
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';

// Uscreen
import { UscreenService } from './uscreen.service';
import { HelpComponent } from './help/help.component';
import { SettingsComponent } from './settings/settings.component';
import { DetailsComponent } from './manage/details/details.component';
import { ManageProfileComponent } from './manage/profile/profile.component';

import { SettingsClassesComponent } from './settings/classes/classes.component';
import { PagesComponent } from './settings/pages/pages.component';
import { HomePagesComponent } from './settings/pages/home/home.component';
import { TermsPagesComponent } from './settings/pages/terms/terms.component';
import { PrivacyPagesComponent } from './settings/pages/privacy/privacy.component';
import { LabelsPagesComponent } from './settings/pages/labels/labels.component';
import { ImagesComponent } from './settings/images/images.component';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { CertsComponent } from './settings/certs/certs.component';
import { ProfileComponent } from './profile/profile.component';
import { SearchComponent } from './search/search.component';
import { AlertsComponent } from './alerts/alerts.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { PostClassComponent } from './post-class/post-class.component';
import { TypeMetadataComponent } from './post-class/type-metadata/type-metadata.component';
import { TimingMetadataComponent } from './post-class/timing-metadata/timing-metadata.component';
import { LocationMetadataComponent } from './post-class/location-metadata/location-metadata.component';
import { ExtraInfoMetadataComponent } from './post-class/extra-info-metadata/extra-info-metadata.component';
import { ReviewInfoComponent } from './post-class/review-info/review-info.component';
import { VerificationStatusComponent } from './manage/profile/verification-status/verification-status.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './home/home.component';
import { SingleAlertComponent } from './alerts/single-alert/single-alert.component';
import { SingleAlertSubmissionComponent } from './alerts/single-alert-submission/single-alert-submission.component';
import { SingleCertComponent } from './settings/certs/single-cert/single-cert.component';
import { PostClassSuccessComponent } from './post-class/success/post-class-success.component';
import { FindClassComponent } from './find-class/find-class.component';
import { BrowseComponent } from './find-class/browse/browse.component';
import { ClassDetailsComponent } from './find-class/class-details/class-details.component';
import { CoverClassSuccessComponent } from './find-class/success/cover-class-success.component';
import { TermsAndConditionsComponent } from './content/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './content/privacy-policy/privacy-policy.component';
import { EscapeHtmlPipe } from './pipes/keep-html.pipe.ts.pipe';
import { FaqsPageComponent } from './settings/pages/faqs-page/faqs-page.component';
import { FaqsComponent} from './content/faqs/faqs.component';
import { FindOrPostComponent } from './find-or-post/find-or-post.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NavbarComponent,
    HelpComponent,
    SettingsComponent,
    ManageDetailsComponent,
    DetailsComponent,
    ManageProfileComponent,
    SettingsClassesComponent,
    PagesComponent,
    LabelsPagesComponent,
    ImagesComponent,
    CertsComponent,
    ProfileComponent,
    SearchComponent,
    AlertsComponent,
    HeaderComponent,
    PostClassComponent,
    TypeMetadataComponent,
    TimingMetadataComponent,
    LocationMetadataComponent,
    ExtraInfoMetadataComponent,
    ReviewInfoComponent,
    FooterComponent,
    VerificationStatusComponent,
    HomeComponent,
    SingleAlertComponent,
    SingleAlertSubmissionComponent,
    SingleCertComponent,
    PostClassSuccessComponent,
    FindClassComponent,
    BrowseComponent,
    ClassDetailsComponent,
    CoverClassSuccessComponent,
    TermsPagesComponent,
    PrivacyPagesComponent,
    HomePagesComponent,
    TermsAndConditionsComponent,
    PrivacyPolicyComponent,
    EscapeHtmlPipe,
    FaqsPageComponent,
    FaqsComponent,
    FindOrPostComponent,
    ForgotPasswordComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgbModule,
    AngularFireModule.initializeApp(environment.classcatch),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    AngularEditorModule,
    RouterModule,
    PasswordStrengthMeterModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    GooglePlaceModule
  ],
  providers: [UscreenService],
  bootstrap: [AppComponent]
})
export class AppModule { }
