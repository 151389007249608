import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';

// Import sign up service
import { ManageDetailsService } from '../manage-details.service';
import { UploadService } from '../../upload.service';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit, OnDestroy {
  public imagePath;
  public message: string;
  public uploading: boolean;
  public manage: boolean;
  public profileImg: boolean;
  public saved: boolean;
  
  profileImgPrev: any;
  profReader: any;
  imgURL: any;
  documents: any;
  error: string;
  uploadedDocs: any;

  s = false;
  imgError = false;
  
  form = this.fb.group({
    fname: ['', [Validators.required]],
    lname: ['', Validators.required],
    email: ['', [Validators.required, Validators.email] ],
    postcode: ['', [Validators.required, Validators.pattern('([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})')]],
    town: ['', Validators.required],
    bio: ['', Validators.required],
    available: ['', Validators.required]
  });

  constructor(
    public fb: FormBuilder,
    private manageDetailsService: ManageDetailsService,
    private uploadService: UploadService,
    private renderer: Renderer2,
    private router: Router
  ) { 
    this.router.events.subscribe((event)  => {
      if(event instanceof NavigationEnd) {
          var re = /manage/gi;
          var str = event.url;
          // Search string for manage to change text
          if (str.search(re) == -1 ) { 
            this.manage = false;
          } else { 
            this.manage = true;
          } 
      }
    });
  }

  ngOnInit() { 
    this.renderer.addClass(document.body, "profile-page");

    // Find authenticated user
    this.manageDetailsService.getAuth().subscribe(user => {
      if(user) {
        // When a authenticated user is found grab their details
        console.log(user.uid);
        this.manageDetailsService.getUserDetails(user.uid)
          .subscribe(
            user => {
              if(user) {
                // Fill in the welcome form
                this.form.patchValue(user);
              }
            },
            errors => console.log(errors)
          );
          
        // When a authenticated user is found grab their details
        this.manageDetailsService.getProfileDetails(user.uid)
          .subscribe(
            profile => {
              if(profile) {
                // Fill in the welcome form
                this.form.patchValue(profile);

                // Load the document refs
                this.uploadedDocs = {
                  insurance: profile["insurancefile"],
                  faid: profile["firstaidfile"],
                  profile: profile["picture"]
                };

                this.profileImg = profile["picturesrc"];
              }
            },
            errors => console.log(errors)
          );
      }
    });

    // Documents array
    this.documents = [];
  }

  ngOnDestroy() {
    // Remove body class
    this.renderer.removeClass(document.body, "profile-page");
  }

  async save() {
    // If the form is valid and it has insurance and first aid or previously uploaded documents then save
    if(this.form.valid && this.documents.insurance && this.documents.faid || this.form.valid && this.uploadedDocs) {
      
      // If replacements are present upload them
      if(this.documents.insurance && this.documents.faid) {
        // Uploading
        this.uploading = true;

        // Upload insurance and first aid documents
        const uploadInsurance = <string>await this.uploadService.uploadFile(this.documents.insurance);
        const uploadFistAid = <string>await this.uploadService.uploadFile(this.documents.faid);

        this.uploadedDocs = {
          insurance: uploadInsurance,
          faid: uploadFistAid
        }
      }

      // If profile pic is present upload it
      if(this.imagePath && this.imagePath.length) {
        // Uploading
        this.uploading = true;

        // Upload profile
        const uploadProfilePic = <string>await this.uploadService.uploadFile(this.imagePath[0]);
        
        // Add to refs
        this.uploadedDocs["profile"] = uploadProfilePic;
      }

      // Create user & pass file refs
      this.manageDetailsService.createUser(
        this.form.value,
        this.uploadedDocs,
        this.manage
      )
      .catch((err) => {
        this.error = "Could not create profile";
      })
      .then(() => {
        this.uploading = false;
        this.profileImgPrev = null;

        // Notify the user that the save was completed
        if(this.manage == true) {
          this.saved = true;

          // Scroll to the top
          window.scroll(0,0);

          setTimeout(() => {
            this.saved = null;

            // Scroll to the top
            window.scroll(0,0);
          }, 4000);
        }
      });

    } else {
      // Set submitted flag
      this.s = true;
      // Scroll to the top
      window.scroll(0,0);
    }
  }

  storeFileRef(file, ref) {
    console.log(file, ref);
    // Create an array of files for upload
    this.documents[ref] = file;
  }

  previewImg(files) {
    if (files.length === 0)
      return;
    
    // Check type
    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "Only images are supported.";
      return;
    }
    
    // Read, measure and preview file
    this.profReader = new FileReader();
    this.imagePath = files;
    this.profReader.readAsDataURL(files[0]); 
    this.profReader.onload = (_event) => { 
      let image = new Image();
      image.src = this.profReader.result;

      image.onload = () => {
        // access image size here
        if(image.width > 1000) {
          this.imgError = true;
        }
        if(image.height > 2000) {
          this.imgError = true;
        }
      };

      this.profileImg = null;
      this.profileImgPrev = this.profReader.result; 
    }
  }

  removeProfImage() {
    this.profileImgPrev = null;
    /* TODO */
    /* RESET FORM INPUT */
    this.imgError = false;
  }

  deleteProfImage() {
    this.manageDetailsService.deleteProfileImage();
  }

}
