import { Injectable } from '@angular/core';

// FS
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase/app';
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";

export interface Settings { 
  content: string;
}

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  private settingsCollection: AngularFirestoreCollection;
  private profilesCollection: AngularFirestoreCollection;
  settings: Observable<any[]>;
  profiles: Observable<any[]>;

  userId: any;

  constructor(
    public afs: AngularFirestore,
    private afAuth: AngularFireAuth
  ) {
    this.settingsCollection = afs.collection('settings');
    this.profilesCollection = afs.collection('profiles');
  }

  getUserId() {
    return this.afAuth.authState;
  }

  getUsersName(id) {
    return this.afs.collection('users').doc(id).valueChanges();
  }

  getProfile(slug) {
    let parts = slug.split("-");
    // Split up the slug and assign a profile
    return this.afs.collection('profiles').doc(parts[2]).valueChanges();
  }

  getClassesBySlug(slug) {
    let parts = slug.split("-");
    // Split up the slug and assign a profile
    return this.afs.collection('profiles').doc(parts[2]).collection('classes').valueChanges();
  }

  getUserSlug() {
    return new Promise((resolve, reject) => {
      this.getUserId()
        .subscribe(
          user => {
            if(user) {
              this.profileSlug(user.uid)
              .subscribe(
                profile => {
                  if(profile) resolve(this.buildProfileSlug(profile));
                }
              );
            }
          }
        );
    });
  }

  unapproveCertificate(userId, type, style, certId) {
    let profile = this.profilesCollection
      .doc(userId)
      .collection('classes')
      .doc(type)
      .collection('certificates')
      .doc(style)
      .update({
        verified: false
      });
    
    let admin = this.settingsCollection
      .doc('cert-settings')
      .collection('certificates')
      .doc(certId)
      .update({
        approved: false
      });
    
    // Resolve on completion
    return Promise.all([profile]);
  }

  approveCertificate(userId, type, style, certId) {
    let profile = this.profilesCollection
      .doc(userId)
      .collection('classes')
      .doc(type)
      .collection('certificates')
      .doc(style)
      .update({
        verified: true
      });
    
    let admin = this.settingsCollection
      .doc('cert-settings')
      .collection('certificates')
      .doc(certId)
      .update({
        approved: true
      });
    
    // Resolve on completion
    return Promise.all([profile]);
  }

  buildProfileSlug(profData) {
    if(profData && profData.fname && profData.lname && profData.userId) {
      return profData.fname.toLowerCase() + "-" + profData.lname.toLowerCase() + "-" + profData.userId;
    }
  }

  profileSlug(id) {
    return this.afs.collection('profiles').doc(`${id}`)
      .valueChanges();
  }

  getClasses() {
    return this.settingsCollection.doc('class-settings').collection('classes').valueChanges()
  }
  getCertificates() {
    return this.settingsCollection
    .doc('cert-settings')
    .collection('certificates', ref => ref.orderBy("date", "desc"))
    .snapshotChanges()
    .pipe(
        map(actions => {
          return actions.map(a => {
              const data = a.payload.doc.data();
              const id = a.payload.doc.id;
              return { id, ...data };
          });
        })
    );
  }
  updateClass(value, id) {
    return this.settingsCollection.doc('class-settings').collection('classes').doc(id).update(value);
  }
  deleteClass(id) {
    return this.settingsCollection.doc('class-settings').collection('classes').doc(id).delete();
  }
  saveNewClass(value){
    // Persist ID
    value.id = this.afs.createId();
    return this.settingsCollection.doc('class-settings').collection('classes').doc(value.id).set(value);
  }
  getHomeSettings() {
    return this.settingsCollection.doc('page-settings').collection('pages').doc('Home').valueChanges();
  }
  saveHomeSettings(value) {
    this.settingsCollection.doc('page-settings').collection('pages').doc('Home').set(value, {merge: true});
  }
  getTermsSettings() {
    return this.settingsCollection.doc('page-settings').collection('pages').doc('Terms').valueChanges();
  }
  saveTermsSettings(value) {
    this.settingsCollection.doc('page-settings').collection('pages').doc('Terms').set(value, {merge: true});
  }
  getPrivacySettings() {
    return this.settingsCollection.doc('page-settings').collection('pages').doc('Privacy').valueChanges();
  }
  savePrivacySettings(value) {
    this.settingsCollection.doc('page-settings').collection('pages').doc('Privacy').set(value, {merge: true});
  }
  getLabelsSettings() {
    return this.settingsCollection.doc('page-settings').collection('pages').doc('Labels').valueChanges();
  }
  saveLabelsSettings(value) {
    this.settingsCollection.doc('page-settings').collection('pages').doc('Labels').set(value, {merge: true});
  }
  getFAQsSettings() {
    return this.settingsCollection.doc('page-settings').collection('faqs')
      .snapshotChanges()
      .pipe(
          map(actions => {
            return actions.map(a => {
                const data = a.payload.doc.data();
                const id = a.payload.doc.id;
                return { id, ...data };
            });
          })
      );
  }
  addFAQsSettings(value) {
    this.settingsCollection.doc('page-settings').collection('faqs').add({ 'title': value.title, 'content': value.content});
  }
  deleteFAQsSettings(value) {
    this.settingsCollection.doc('page-settings').collection('faqs').doc(value.id).delete();
  }
}
