import { Component, OnInit } from '@angular/core';
import {ClassService} from '../../class.service';
import {LocationMetadata, NoteMetadata, TimingMetadata, TypeMetadata} from '../../models/post-class-form-data';

@Component({
  selector: 'app-review-info',
  templateUrl: './review-info.component.html',
  styleUrls: ['./review-info.component.scss']
})
export class ReviewInfoComponent implements OnInit {

  typeMetadata: TypeMetadata;
  locationMetadata: LocationMetadata;
  timingMetadata: TimingMetadata;
  noteMetadata: NoteMetadata;

  constructor(private dataService: ClassService) { }

  ngOnInit() {
    this.typeMetadata = this.dataService.getTypeMetadata();
    this.timingMetadata = this.dataService.getTimingMetadata();
    this.locationMetadata = this.dataService.getLocationMetadata();
    this.noteMetadata = this.dataService.getNoteMetadata();
  }

  postClass() {
    this.dataService.postClass();
  }

  updateClass() {
    this.dataService.updateClass();
  }

  get class() {
    return this.dataService.formDataClass;
  }

}
