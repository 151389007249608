import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFireAuth } from '@angular/fire/auth';
import { finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  uploadPercent: Observable<number>;
  downloadURL: Observable<string>;

  constructor(
    private afStorage: AngularFireStorage
    ) {
      
    }

  uploadFile(file) {
    return new Promise((resolve, reject) => {
      const randomId = Math.random().toString(36).substring(2);
      const filePath = `/uploads/general/${randomId}`;
      const fileRef = this.afStorage.ref(filePath);
      const task = this.afStorage.upload(filePath, file);
      
      // observe percentage changes
      this.uploadPercent = task.percentageChanges();

      task.then(() => {
        // get notified when the download URL is available
        resolve(filePath);
      });
    });
  }

  getDownloadURLFromRef(fileLoc) {
    let ref = this.afStorage.ref(fileLoc);
    return ref.getDownloadURL();
  }

  uploadForUser(file, userId) {
    const randomId = Math.random().toString(36).substring(2);
    return this.afStorage.upload(`/uploads/users/${userId}/`, file);
  }
}
