import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { SettingsService } from '../../settings.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomePagesComponent implements OnInit {
  form = this.fb.group({
    heroTitle: ['', [Validators.required]],
    intro: ['', [Validators.required] ],
    introParagraph: ['', Validators.required],
    quote: ['', Validators.required],
  });

  constructor(
    public fb: FormBuilder,
    private settingsService: SettingsService
    ) { }

  ngOnInit() {
    this.settingsService.getHomeSettings()
      .subscribe(
        home => this.form.patchValue(home),
        errors => console.log(errors)
      )
  }

  save() {
    if(this.form.valid) {
      console.log("Hi");
      this.settingsService.saveHomeSettings(this.form.value);
    }
  }

}
