import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Auth
import { AuthGuard } from './core/auth.guard';
import { AdminAuthGuard } from './core/admin-auth.guard';

// Components
import { LoginComponent } from './login/login.component';
import { ProfileComponent } from './profile/profile.component';
import { HelpComponent } from './help/help.component';
import { SettingsComponent } from './settings/settings.component';
import { DetailsComponent } from './manage/details/details.component';
import { ManageProfileComponent } from './manage/profile/profile.component';
import { ManageDetailsComponent } from './manage/manage-details.component';
import { SettingsClassesComponent } from './settings/classes/classes.component';
import { CertsComponent } from './settings/certs/certs.component';
import { PostClassComponent } from './post-class/post-class.component';
import { TypeMetadataComponent } from './post-class/type-metadata/type-metadata.component';
import { TimingMetadataComponent } from './post-class/timing-metadata/timing-metadata.component';
import { LocationMetadataComponent } from './post-class/location-metadata/location-metadata.component';
import { ExtraInfoMetadataComponent } from './post-class/extra-info-metadata/extra-info-metadata.component';
import { ReviewInfoComponent } from './post-class/review-info/review-info.component';
import { PostClassGuard } from './core/post-class.guard';
import { HomeComponent } from './home/home.component';
import { AlertsComponent } from './alerts/alerts.component';
import { PostClassSuccessComponent } from './post-class/success/post-class-success.component';
import { FindClassComponent } from './find-class/find-class.component';
import { BrowseComponent} from './find-class/browse/browse.component';
import { FindOrPostComponent } from './find-or-post/find-or-post.component';
import { ClassDetailsComponent} from './find-class/class-details/class-details.component';
import { CoverClassSuccessComponent} from './find-class/success/cover-class-success.component';
import { PagesComponent } from './settings/pages/pages.component';
import { HomePagesComponent } from './settings/pages/home/home.component';
import { TermsPagesComponent } from './settings/pages/terms/terms.component';
import { PrivacyPagesComponent } from './settings/pages/privacy/privacy.component';
import { LabelsPagesComponent } from './settings/pages/labels/labels.component';
import { TermsAndConditionsComponent } from './content/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './content/privacy-policy/privacy-policy.component';
import {FaqsPageComponent} from './settings/pages/faqs-page/faqs-page.component';
import {FaqsComponent} from './content/faqs/faqs.component';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';

const routes: Routes = [
  { path: 'sign-in', component: LoginComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'alerts', component: AlertsComponent },
  { path: 'terms-and-conditions', component: TermsAndConditionsComponent },
  { path: 'faqs', component: FaqsComponent},
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'profile/:slug', component: ProfileComponent },
  { path: 'post-class', component: PostClassComponent, canActivate: [AuthGuard], canActivateChild: [PostClassGuard],
    children: [
      { path: '', redirectTo: 'type', pathMatch: 'full' },
      { path: 'type', component: TypeMetadataComponent },
      { path: 'timing', component: TimingMetadataComponent },
      { path: 'location', component: LocationMetadataComponent },
      { path: 'note', component: ExtraInfoMetadataComponent },
      { path: 'review', component: ReviewInfoComponent },
      { path: 'success', component: PostClassSuccessComponent }
    ]},
  { path: 'find-class', component: FindClassComponent,
    children: [
      { path: 'browse', component: BrowseComponent },
      { path: 'class/:id', component: ClassDetailsComponent },
      { path: 'success', component: CoverClassSuccessComponent },
      { path: '', redirectTo: 'browse', pathMatch: 'full' },
    ] },
  { path: 'help', component: HelpComponent, canActivate: [AuthGuard] },
  { path: 'settings', component: SettingsComponent, canActivate: [AdminAuthGuard] },
  { path: 'settings/classes', component: SettingsClassesComponent, canActivate: [AdminAuthGuard] },
  { path: 'settings/certs', component: CertsComponent, canActivate: [AdminAuthGuard] },
  { path: 'settings/pages', component: PagesComponent, canActivate: [AdminAuthGuard] },
  { path: 'settings/pages/home', component: HomePagesComponent, canActivate: [AdminAuthGuard] },
  { path: 'settings/pages/terms', component: TermsPagesComponent, canActivate: [AdminAuthGuard] },
  { path: 'settings/pages/privacy', component: PrivacyPagesComponent, canActivate: [AdminAuthGuard] },
  { path: 'settings/pages/labels', component: LabelsPagesComponent, canActivate: [AdminAuthGuard] },
  { path: 'settings/pages/faqs', component: FaqsPageComponent, canActivate: [AdminAuthGuard] },
  { path: 'sign-up', component: ManageDetailsComponent },
  { path: 'sign-up/welcome', component: DetailsComponent },
  { path: 'sign-up/profile', component: ManageProfileComponent },
  { path: 'manage/details', component: DetailsComponent, canActivate: [AuthGuard] },
  { path: 'manage/profile', component: ManageProfileComponent, canActivate: [AuthGuard] },
  { path: 'find-or-post', component: FindOrPostComponent, canActivate: [AuthGuard] },
  { path: '', component: HomeComponent },
  { path: '', redirectTo: 'sign-in', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
