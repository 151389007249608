import { Injectable, NgZone } from '@angular/core';

import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable, from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import {map} from 'rxjs/operators';
import { auth } from 'firebase/app';

import { ManageDetailsService } from '../manage/manage-details.service';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  authState: AngularFireAuth = null;
  private profilesCollection: AngularFirestoreCollection;

  constructor(
    public afAuth: AngularFireAuth,
    public afs: AngularFirestore,
    public router: Router,
    private zone: NgZone,
    private manageDetailsService: ManageDetailsService
  ) {
    this.profilesCollection = afs.collection('profiles');
  }

  getUserId() {
    return this.afAuth.authState;
  }

  getUser(): Observable<any> {
    return this.afAuth.authState.pipe(
      mergeMap(authState => {
        if (authState) {
          return from(this.afs.doc(`users/${authState.email}`).get());
        } else {
          this.router.navigate(["/sign-in"]);
        }
      })
    );
  }

  getAdmin(): Observable<any> {
    return this.afAuth.authState.pipe(
      mergeMap(authState => {
        if (authState) {
          return from(this.afs.doc(`admin/${authState.email}`).get());
        } else {
          this.router.navigate(["/"]);
        }
      })
    );
  }

  checkForAdmin(email) {
    return this.afs.doc(`admin/${email}`).ref.get()
  }

  checkForUser(user) {
    this.afs.doc(`users/${user.email}`).ref.get()
    .then(docSnapshot => {
      if (docSnapshot.exists) {
        // this.router.navigate(["/"]);
      }
    });
  }

  signInWithEmail(value) {
    return this.afAuth.auth.signInWithEmailAndPassword(value.email, value.password)
       .then((res) => {
        let user = res.user;
        let profile:any = res.additionalUserInfo.profile;

        console.log(res);

        this.checkForProfile(user, profile);
    });
  }

  signInWithGoogle() {
    return this.afAuth.auth.signInWithPopup(new auth.GoogleAuthProvider())
      .then((res) => {
        let user = res.user;
        let profile:any = res.additionalUserInfo.profile;
        console.log(res);
        
        this.checkForProfile(user, profile);
      });
  }

  signInWithFacebook() {
    return this.afAuth.auth.signInWithPopup(new auth.FacebookAuthProvider())
      .then((res) => {
        let user = res.user;
        let profile:any = res.additionalUserInfo;

        console.log(res);
        
        this.checkForProfile(user, profile);
      });
  }

  checkForProfile(user, profile?){
    console.log(user.uid);
    // Check for location
    this.profilesCollection.doc(`${user.uid}`)
    .get()
    .pipe(map(doc => doc.data()))
    .subscribe(
      userProfile => {
        if(userProfile) {
          // Need a location to post a class
          if(!userProfile.location) {
            this.manageDetailsService.createUserAfterSignUp(user, profile);

            // Run zone because of thrid party
            this.zone.run(() => {
              if(user) this.router.navigate(["/sign-up/welcome"]);
            });
          } else {
            // Run zone because of thrid party
            this.zone.run(() => {
              if(user) this.router.navigate(["/find-class/browse"]);
            });
          }
        } else {
          this.manageDetailsService.createUserAfterSignUp(user, profile, false);

          // Run zone because of thrid party
          this.zone.run(() => {
            if(user) this.router.navigate(["/sign-up/welcome"]);
          });
        }
    },
    errors => console.log(errors) );
  }

  endSession() {
    this.afAuth.auth.signOut().then(() => {
      this.router.navigate(["/sign-in"]);
    });
  }

  resetPassword(email: string) {
    return this.afAuth.auth.sendPasswordResetEmail(email);
  }

}
