import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';

// FS
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase/app';
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { resolve } from 'url';
import * as firebase from 'firebase/app';
import * as geofirex from 'geofirex';

// Services
import { PostCodeQueryService } from '../post-code-query.service';

@Injectable({
  providedIn: 'root'
})
export class ManageDetailsService {
  private profilesCollection: AngularFirestoreCollection;
  private usersCollection: AngularFirestoreCollection;
  private geo: any;
  userId: any;

  constructor(
    public afs: AngularFirestore,
    public router: Router,
    private afAuth: AngularFireAuth,
    private zone: NgZone,
    private postCodeService: PostCodeQueryService
  ) {
    this.profilesCollection = afs.collection('profiles');
    this.usersCollection = afs.collection('users');
    this.geo = geofirex.init(firebase);
    this.afAuth.authState.subscribe(user => {
      if(user) this.userId = user.uid
    });
  }

  getAlerts(id) {
    console.log(id);
    return this.usersCollection.doc(id).collection('alerts').valueChanges();
  }

  getAuth() {
    return this.afAuth.authState;
  }

  signUpWithEmail(value) {
    return this.afAuth.auth.createUserWithEmailAndPassword(value.email, value.password)
       .then((res) => {
          this.zone.run(() => {
            if(res.user.uid) {
              let user = res.user;
              let profile:any = res.additionalUserInfo;

              console.log(res);

              this.createUserAfterSignUp(user, profile);

              this.router.navigate(['sign-up/welcome']);
            }
          });
       });
  }

  signUpWithFacebook() {
    return this.afAuth.auth.signInWithPopup(new auth.FacebookAuthProvider())
    .then((res) => { 
        this.zone.run(() => {

          if(res.additionalUserInfo.profile && res.user.uid) {
            let user = res.user;
            let profile:any = res.additionalUserInfo;

            console.log(res);

            this.createUserAfterSignUp(user, profile);
          }

          if(res.additionalUserInfo.profile && res.user.uid) {
            this.router.navigate(['sign-up/welcome']);
          }
        });
      })
  }

  signUpWithGoogle() {
    return this.afAuth.auth.signInWithPopup(new auth.GoogleAuthProvider())
    .then((res) => { 
        this.zone.run(() => {
          
          if(res.additionalUserInfo.profile && res.user.uid) {
            let user = res.user;

            let profile:any = res.additionalUserInfo;

            console.log(res);

            this.createUserAfterSignUp(user, profile);
          }
          if(res.additionalUserInfo.profile && res.user.uid) {
            this.router.navigate(['sign-up/welcome']);
          }
        });
      })
  }

  createUserAfterSignUp(user, info?, hasProfile?){
    this.usersCollection.doc(`${user.uid}`).ref
      .get()
      .then((documentSnapshot) => {
        console.log(documentSnapshot.exists);

        let profile:any = info.profile;

        if(!documentSnapshot.exists) {
          // Save email first via set
          if(user && user.email) {
            // Fill in email
            this.usersCollection.doc(`${user.uid}`).set({
              email: user.email
            });
            // Fill in profile
            this.profilesCollection.doc(`${user.uid}`).set({
              email: user.email
            });
          }
          
          // Save Facebook
          if(profile && profile.id && info.providerId == "facebook.com") {
            // construct the URL to the profile picture, with a custom height
            // alternatively, use '?type=small|medium|large' instead of ?height=
            let photoUrl = "https://graph.facebook.com/" + profile.id + "/picture?height=500";

            // Fill in email and picture
            this.profilesCollection.doc(`${user.uid}`).update({
              picturesrc: photoUrl
            });
          }

          if(profile && profile.picture && info.providerId != "facebook.com") {
            // Fill in profile
            this.profilesCollection.doc(`${user.uid}`).update({
              picturesrc: profile.picture.data.url
            });
          }
        }
        if(hasProfile == false) {
          // Fill in profile
          this.profilesCollection.doc(`${user.uid}`).set({
            email: user.email
          });
          
          if(profile && profile.picture && info.providerId != "facebook.com") {
            // Fill in profile
            this.profilesCollection.doc(`${user.uid}`).update({
              picturesrc: profile.picture.data.url
            });
          }
        }
    });
  }

  getUserDetails(uid) {
    return this.usersCollection.doc(`${uid}`).valueChanges();
  }

  getProfileDetails(uid) {
    return this.profilesCollection.doc(`${uid}`).valueChanges();
  }

  getClassesDetails(uid) {
    return this.profilesCollection
    .doc(`${uid}`)
    .collection('classes')
    .valueChanges();
  }

  saveClass(type, levels, styles, specialities) {
    // Save as new class
    return this.profilesCollection
    .doc(`${this.userId}`)
    .collection('classes')
    .doc(type)
    .set({
      type: type,
      levels: levels,
      styles: styles,
      specialities: specialities
    });
  }

  getCertificates(type) {
    // Save as new class
    return this.profilesCollection
      .doc(`${this.userId}`)
      .collection('classes')
      .doc(`${type}`)
      .collection('certificates')
      .valueChanges();
  }

  deleteCert(name, type) {
    // Delete cert
    return this.profilesCollection
      .doc(`${this.userId}`)
      .collection('classes')
      .doc(`${type}`)
      .collection('certificates')
      .doc(name)
      .delete()
  }

  addCert(name, type) {
    // Save as new class
    return this.profilesCollection
      .doc(`${this.userId}`)
      .collection('classes')
      .doc(`${type}`)
      .collection('certificates')
      .doc(name)
      .set({
        name: name,
        verified: false
      });
  }

  saveCertFile(name, certRef, type) {
    console.log(name, certRef, type);
    // Save as new class
    return this.profilesCollection
      .doc(`${this.userId}`)
      .collection('classes')
      .doc(`${type}`)
      .collection('certificates')
      .doc(name)
      .update({
        file: certRef
      });
  }

  createUser(profileData, files, manage) {

    // Save new create user
    return this.usersCollection.doc(`${this.userId}`).update({ 
      fname: profileData.fname, 
      lname: profileData.lname, 
      email: profileData.email
    })
    .then((result) => {
      let profileId = this.userId;

      // Create profile
      this.createPofile(profileData, files)
        .then(() => {
          console.log(manage);
          if(manage == false) {
            // Navigate to the next page to add classes
            this.router.navigate(['/sign-up/profile']);
          }
        })
        .catch((err) => {
          console.log(err);
        })
    })
    .catch((err) => {
      console.log(err);
    });
  }

  createPofile(profileData, files) {

    return new Promise((resolve, reject) => {
      this.profilesCollection.doc(`${this.userId}`).update({ 
        userId: this.userId,
        fname: profileData.fname, 
        lname: profileData.lname,
        postcode: profileData.postcode,
        town: profileData.town,
        bio: profileData.bio,
        available: profileData.available
      })
      .then(() => {
        let insuranceDoc, firstAid, profilePic;

        // Update any files that are present

        if(files.profile) {
          insuranceDoc = this.profilesCollection.doc(`${this.userId}`).update({
            picture: files.profile
          });
        }
        if(files.faid) {
          firstAid = this.profilesCollection.doc(`${this.userId}`).update({
            firstaidfile: files.faid,
            firstaidverified: false
          });
        }
        if(files.insurance) {
          profilePic = this.profilesCollection.doc(`${this.userId}`).update({
            insuranceverified: false,
            insurancefile: files.insurance
          });
        }

        let location = new Promise((resolve, reject) => {
          // Location API Insert
          this.postCodeService.getLocationDataFromPostCode(profileData.postcode)
            .subscribe(
              location => {
                if(location) {
                  this.profilesCollection.doc(`${this.userId}`).update({
                    location: this.geo.point(location.latitude, location.longitude).data,
                    latitude: location.latitude,
                    longitude: location.longitude
                  }).then(() => {
                    resolve();
                  })
                  .catch((errors) => {
                    reject(errors);
                  })
                }
              },
              errors => reject(errors)
            );
        });

        // Resolve on completion
        Promise.all([insuranceDoc, firstAid, profilePic, location]).then(function(values) {
          resolve();
        })
        .catch(() => {
          reject();
        });
      })
      .catch(() => {
        reject();
      });
    }); 
  }

  deleteProfileImage() {
    this.profilesCollection.doc(`${this.userId}`)
      .update({
        picturesrc: firebase.firestore.FieldValue.delete(),
        picture: firebase.firestore.FieldValue.delete()
      });
    
  }
}
