import { Component, OnInit } from '@angular/core';
import {AuthService} from '../core/auth.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  form: FormGroup;
  emailSentSuccess: boolean;
  emailSentError: boolean;
  constructor(private authService: AuthService, private fb: FormBuilder) {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
    this.emailSentSuccess = false;
    this.emailSentError = false;
  }

  ngOnInit() {
  }

  forgotPassword() {
    this.emailSentSuccess = false;
    this.emailSentError = false;
    this.authService.resetPassword(this.form.get('email').value).then(
      res => {
        this.emailSentSuccess = true;
      },
      error => {
        this.emailSentError = true;
      }
    );
  }
}
