import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

// Import sign up service
import { ManageDetailsService } from './manage-details.service';

@Component({
  selector: 'app-manage-details',
  templateUrl: './manage-details.component.html',
  styleUrls: ['./manage-details.component.scss']
})
export class ManageDetailsComponent implements OnInit {
  error: string;

  form = this.fb.group({
    email: ['', [Validators.required]],
    password: ['', Validators.required]
  });

  constructor(
    private manageDetailsService: ManageDetailsService,
    public fb: FormBuilder
  ) { }

  ngOnInit() {
  }

  signUpEmail() {
    if(this.form.valid) {
      this.manageDetailsService.signUpWithEmail(this.form.value)
        .catch(
          (err) => this.error = err
        );
    }
  }

  signUpFB() {
    this.manageDetailsService.signUpWithFacebook()
      .catch(
        (err) => this.error = err
      );
  }

  signUpGo() {
    this.manageDetailsService.signUpWithGoogle()
      .catch(
        (err) => this.error = err
      );
  }

}
