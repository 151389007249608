import {ChangeDetectorRef, Component, OnInit, NgZone} from '@angular/core';
import {SearchService} from '../../search.service';
import {ManageDetailsService} from '../../manage/manage-details.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-browse',
  templateUrl: './browse.component.html',
  styleUrls: ['./browse.component.scss']
})
export class BrowseComponent implements OnInit {

  classes: any;
  profile: any;
  userActivities: any;
  private user: any;

  constructor(private searchService: SearchService,
              private router: Router,
              private cdf: ChangeDetectorRef,
              private zone: NgZone,
              private detailsService: ManageDetailsService ) { }


  ngOnInit() {
    this.detailsService.getAuth().subscribe( user => {
      if (user) {
        this.user = user;
        this.detailsService.getProfileDetails(user.uid).subscribe((profile: any) => {
          this.profile = profile;
          this.searchService.getVerifiedActivitiesAndStyles(profile);
          this.searchService.verifiedActivities.subscribe( activities => this.userActivities = activities);
          this.searchService.sortBySoonest(profile);
        });
      } else {
        this.searchService.getAllClasses();
      }

      this.searchService.classes.subscribe(classes => {
        this.classes = classes;
        this.cdf.detectChanges();
      });
    });
  }

  hasExpired(klass) {
    return klass.dateTime < new Date();
  }

  applyFilter(option) {
    if (option === 'soonest') {
      this.searchService.sortBySoonest(this.profile);
    }
    else {
      this.searchService.filterClassesByActivity(this.profile, option);
    }
  }
  selectClass(classId) {
    if (this.user) {
      // Run zone because of thrid party
      this.zone.run(() => {
        this.router.navigate(['/find-class', 'class', classId]);
      });
    } else {
      this.router.navigate(['/sign-up']);
    }
  }
}
