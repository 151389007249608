import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateChild, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Router } from '@angular/router';
import {ClassService} from '../class.service';


@Injectable({
  providedIn: 'root'
})
export class PostClassGuard implements CanActivateChild {
  private readonly parentRoute;
  private readonly locationStepUrl;
  private readonly typeStepUrl;
  private readonly timingStepUrl;
  private readonly reviewStepUrl;
  private readonly successStepUrl;

  constructor(
    private router: Router,
    private dataService: ClassService
  ) {
    this.parentRoute = '/post-class/';
    this.locationStepUrl = this.parentRoute + 'location';
    this.timingStepUrl = this.parentRoute + 'timing';
    this.typeStepUrl = this.parentRoute + 'type';
    this.reviewStepUrl = this.parentRoute + 'review';
    this.successStepUrl = this.parentRoute + 'success';

  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (state.url === this.reviewStepUrl || state.url === this.successStepUrl) {
      if (!this.dataService.typeMetadataValid()) {
        this.navigateToStep('type');
      }

      if (!this.dataService.timingMetadataValid()) {
        this.navigateToStep('timing');
      }

      if (!this.dataService.locationMetadataValid()) {
        this.navigateToStep('location');
      }
    }

    if (state.url === this.locationStepUrl) {
      if (!this.dataService.typeMetadataValid()) {
        this.navigateToStep('type');
      }
      if (!this.dataService.timingMetadataValid()) {
        this.navigateToStep('timing');
      }
    }

    if (state.url === this.timingStepUrl) {
      if (!this.dataService.typeMetadataValid()) {
        this.navigateToStep('type');
      }
    }

    return true;
  }

  private navigateToStep(stepName) {
    this.router.navigate(['post-class', stepName]);
  }
}
