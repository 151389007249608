import { Component, OnInit } from '@angular/core';
import {ClassService} from '../../class.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PostCodeQueryService} from '../../post-code-query.service';
import {LocationMetadata} from '../../models/post-class-form-data';
import {Router} from '@angular/router';
import {Address} from 'ngx-google-places-autocomplete/objects/address';
import {AddressComponent} from 'ngx-google-places-autocomplete/objects/addressComponent';

@Component({
  selector: 'app-location-metadata',
  templateUrl: './location-metadata.component.html',
  styleUrls: ['./location-metadata.component.scss']
})
export class LocationMetadataComponent implements OnInit {
  private locationMedata: LocationMetadata;
  form: FormGroup;

  location: {latitude: number, longitude: number};

  constructor(private dataService: ClassService,
              private postCodeService: PostCodeQueryService,
              private router: Router,
              private formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({
      autoCompleteEnabled: [true],
      address: ['', Validators.required],
      townOrCity: [''],
      postCode: ['']
    });
    this.location = { longitude: 0, latitude: 0};
  }

  ngOnInit() {
    this.initializeForm();
    this.form.controls.autoCompleteEnabled.valueChanges.subscribe(value => {
      if (value) {
        this.form.get('townOrCity').setValidators([]);
        this.form.get('townOrCity').updateValueAndValidity();
        this.form.get('postCode').setValidators([]);
        this.form.get('postCode').updateValueAndValidity();
      } else {
        this.form.get('townOrCity').setValidators([Validators.required]);
        this.form.get('townOrCity').updateValueAndValidity();
        this.form.get('postCode').setValidators([Validators.required]);
        this.form.get('postCode').updateValueAndValidity();
      }
    });
  }

  initializeForm() {
    this.form.patchValue(this.dataService.getLocationMetadata());
  }

  saveData() {
    if (this.form.valid) {
          this.locationMedata = this.form.value;
          if (this.form.get('autoCompleteEnabled').value === true) {
            this.locationMedata.longitude = this.location.longitude;
            this.locationMedata.latitude = this.location.latitude;
            this.dataService.setLocationMetadata(this.locationMedata);
            console.log(this.dataService.getLocationMetadata());
            this.router.navigate(['/post-class', 'note']);
          } else {
            this.postCodeService.getLocationDataFromPostCode(this.form.controls.postCode.value)
              .subscribe(location => {
                this.locationMedata = this.form.value;
                this.locationMedata.longitude = location.longitude;
                this.locationMedata.latitude = location.latitude;
                this.dataService.setLocationMetadata(this.locationMedata);
                console.log(this.dataService.getLocationMetadata());
                this.router.navigate(['/post-class', 'note']);
              }, () => {
                alert('Kindly enter valid Postcode');
              });
          }
    }
  }
  public getComponentByType(address: Address, type: string): AddressComponent {
    if (!type) {
      return null;
    }

    if (!address || !address.address_components || address.address_components.length === 0) {
      return null;
    }

    type = type.toLowerCase();

    for (const comp of address.address_components) {
      if (!comp.types || comp.types.length === 0) {
        continue;
      }

      if (comp.types.findIndex(x => x.toLowerCase() === type) > -1) {
        return comp;
      }
    }

    return null;
  }

  handleAddressChange(event) {
    let place = event.name;
    this.getComponentByType(event, 'city');
    let houseNumber = this.getComponentByType(event, 'street_number');
    let street = this.getComponentByType(event, 'route');
    let state = this.getComponentByType(event, 'administrative_area_level_1');
    let city = this.getComponentByType(event, 'locality');
    let country = this.getComponentByType(event, 'country');

    if (houseNumber && houseNumber.long_name && houseNumber.long_name !== place) {
      place = `${place}, ${houseNumber.long_name}`;
    }

    if (state && street.long_name && street.long_name !== place) {
      place = `${place}, ${street.long_name}`;
    }

    if (city && city.long_name && city.long_name !== place) {
      place = `${place}, ${city.long_name}`;
    }

    if (state && state.long_name && state.long_name !== place) {
      place = `${place}, ${state.long_name}`;
    }

    if (country && country.long_name && country.long_name !== place) {
      place = `${place}, ${country.long_name}`;
    }
    this.form.get('address').setValue(place);
    this.location.latitude = event.geometry.location.lat();
    this.location.longitude = event.geometry.location.lng();
  }

}
