import {Time} from '@angular/common';
import {Class} from './class';

export class PostClassFormData {
  private class: Class;
  public timingMetadata: TimingMetadata;
  public locationMetadata: LocationMetadata;
  public noteMetadata: NoteMetadata;
  public typeMetadata: TypeMetadata;

  constructor() {
    this.timingMetadata = new TimingMetadata();
    this.locationMetadata = new LocationMetadata();
    this.noteMetadata = new NoteMetadata();
    this.typeMetadata = new TypeMetadata();
  }
}

export class TimingMetadata {
  time: Time;
  date: Date;
  dateTime: Date;
  spanInMinutes: number;
  spanInWeeks: number;

  constructor(obj?) {
    this.time = obj && obj.time || null;
    this.date = obj && obj.date || null;
    this.dateTime = obj && obj.dateTime || null;
    this.spanInWeeks = obj && obj.spanInWeeks || 2;
    this.spanInMinutes = obj && obj.spanInMinutes || 60;
  }
}

export class LocationMetadata {
  address: string;
  townOrCity: string;
  postCode: string;
  longitude: any;
  latitude: any;

  constructor(obj?) {
    this.address = obj && obj.address || '';
    this.townOrCity = obj && obj.townOrCity || '';
    this.postCode = obj && obj.postCode || '';
    this.longitude = obj && obj.longitude || '';
    this.latitude = obj && obj.latitude || '';
  }

}

export class NoteMetadata {
  note: string;

  constructor(obj?) {
    this.note = obj && obj.note || '';
  }
}

export class TypeMetadata {
  style: string;
  level: string;
  speciality: string;
  type: string;
  certRequiredForStyle: boolean;

  constructor(obj?) {
    this.style = obj && obj.style || null;
    this.level = obj && obj.level || null;
    this.speciality = obj && obj.speciality || null;
    this.type = obj && obj.type || null;
    this.certRequiredForStyle = obj && obj.certRequiredForStyle || false;
  }
}
