import { Component, OnInit } from '@angular/core';
import {ClassService} from '../../class.service';
import {FormBuilder, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-extra-info-metadata',
  templateUrl: './extra-info-metadata.component.html',
  styleUrls: ['./extra-info-metadata.component.scss']
})
export class ExtraInfoMetadataComponent implements OnInit {

  form: FormGroup;

  constructor(private dataService: ClassService,
              private formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({
      note: ['']
    });
  }

  ngOnInit() {
    this.initializeForm();
  }

  initializeForm() {
    this.form.patchValue(this.dataService.getNoteMetadata());
  }

  saveData() {
    if(this.form.valid) {
      this.dataService.setNoteMetadata(this.form.value);
    }
  }
}
