import { Injectable } from '@angular/core';
import {AngularFirestore } from '@angular/fire/firestore';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import * as firebaseApp from 'firebase/app';
import * as geofirex from 'geofirex';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  private geo: any;
  private classesSubject: BehaviorSubject<any>;
  public classes: Observable<any>;
  public verifiedStyles: Observable<string[]>;
  public verifiedActivities: Observable<string[]>;
  private verifiedActivitiesSubject: BehaviorSubject<string[]>;
  private verifiedStylesSubject: BehaviorSubject<string[]>;

  constructor(private db: AngularFirestore) {
    this.classesSubject = new BehaviorSubject(null);
    this.verifiedActivitiesSubject = new BehaviorSubject(null);
    this.verifiedStylesSubject = new BehaviorSubject<string[]>(null);
    this.geo = geofirex.init(firebaseApp);
    this.classes = this.classesSubject.asObservable();
    this.verifiedStyles = this.verifiedStylesSubject.asObservable();
    this.verifiedActivities = this.verifiedActivitiesSubject.asObservable();
  }

  getAllClasses() {
    return this.transformData(this.db.collection('classes').valueChanges()).subscribe((classes: any) => {
        this.classesSubject.next(classes.sort((a, b) => this.sortOnDates(a.dateTime, b.dateTime)));
      });
  }

  sortBySoonest(profile) {
    this.getVerifiedActivitiesAndStyles(profile);
    this.verifiedActivities.subscribe(activities => {
      this.transformData(this.applyImplicitFilters(profile, activities)).subscribe( (classes: any) => {
        this.classesSubject.next(classes.sort((a, b) => this.sortOnDates(a.dateTime, b.dateTime)));
      });
    });
  }

  private sortOnDates(firstDate, secondDate) {
    const now = new Date();
    const firstDateInPast = firstDate <= now;
    const firstDateInFuture = firstDate > now;
    const secondDateInPast = secondDate <= now;
    const secondDateInFuture = secondDate >= now;
    if (firstDateInFuture && secondDateInFuture) {
      return firstDate - secondDate;
    }
    if (firstDateInFuture && secondDateInPast) {
      return -1;
    }
    if (firstDateInPast && secondDateInFuture) {
      return 1;
    }
    if (firstDateInPast && secondDateInPast) {
      return secondDate - firstDate;
    }
  }

  getClass(id) {
    return this.db.collection('classes').doc(`${id}`).valueChanges();
  }

  getUserOfClass(userID) {
    return this.db.collection('profiles').doc(`${userID}`).get().pipe(map(doc => doc.data()));
  }

  filterClassesByActivity(profile,  activity: string) {
    this.getVerifiedActivitiesAndStyles(profile);
    const center = this.geo.point(profile.latitude, profile.longitude);

    this.verifiedActivities.subscribe( activities => {
      this.transformData(this.applyVerifiedActivitiesFilter(activities, this.geo.collection('classes', (collection) => {
        return collection.where('activity', '==', activity);
      }).within(center, 50, 'location')))
        .subscribe( klasses => this.classesSubject.next(klasses));
    });
  }

  private applyImplicitFilters(profile, verifiedActivities: string[]) {
    const center = this.geo.point(profile.latitude, profile.longitude);
    return this.applyVerifiedActivitiesFilter(verifiedActivities, this.geo.collection('classes').within(center, 30, 'location'));
  }

  private transformData(input: Observable<any>): Observable<any> {
    return input.pipe(map(klasses => {
      return klasses.map(klass => {
        if (klass.dateTime) {
          klass.dateTime = klass.dateTime.toDate();
        }
        return klass;
      });
    }));
  }

  private applyVerifiedActivitiesFilter(acitvities: string[], input: Observable<any>): Observable<any> {
    return input.pipe(map( (klasses: any) => {
      return klasses.filter((klass: any) => {
        const verifiedFor = acitvities || [];
        return verifiedFor.includes(klass.activity);
      });
    }));
  }

  getVerifiedActivitiesAndStyles(profile) {
    const verifiedFor = profile.verifiedfor || [];
    this.db.collection('settings').doc('class-settings').collection('classes').valueChanges().subscribe( classes => {
      const verifiedActivitiesArray = verifiedFor.filter((activity) => {
        if (classes.some(e => e.type === activity)) {
          return true;
        }
      });

      const verifiedStylesArray = verifiedFor.filter((style) => {
        if (classes.some(e => {
          if (e.styles.some(s => s.name === style)) {
            return true;
          }
        })) {
          return true;
        }
      });
      this.verifiedActivitiesSubject.next(verifiedActivitiesArray);
      this.verifiedStylesSubject.next(verifiedStylesArray);
    });
  }

  /*getClasses() {
    this.detailsService.getAuth().subscribe( user => {
      if (user) {
        this.detailsService.getClassesDetails(user.uid).subscribe(classes => {
          this.userActivities = classes.map(c => c.type);
        });
      }
    });

    return this.classesSubject.asObservable();
  }
*/
  /*combineResults(activities) {
    const queryResults = activities.map( activity => {
      return this.db.collection('classes', collection =>
        collection.where('activity', '==', activity))
        .snapshotChanges().pipe(map( actions => {
          return actions.map(a => {
            const data: any = a.payload.doc.data();
            const id = a.payload.doc.id;
            return { id, ...data };
          });
        }));
    });
    combineLatest(queryResults).subscribe( classesObservables => {
      const classes = [].concat(...classesObservables).map(c => {
        c.date = c.date.toDate();
        c.time = c.time.toDate();
        return c;
      }).sort((a, b) => Math.abs(Date.now() - a.date - Math.abs(Date.now() - b.date))).reverse();

      this.classesSubject.next(classes);
    });
  }*/
}
