import { Injectable } from '@angular/core';

// FS
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

export interface Help { 
  content: string;
}

@Injectable({
  providedIn: 'root'
})
export class HelpService {
  private pagesCollection: AngularFirestoreCollection;
  pages: Observable<any[]>;

  constructor(public afs: AngularFirestore) {
    this.pagesCollection = afs.collection('pages');
  }

  getHelp() {
    return this.pagesCollection.doc('help').valueChanges();
  }
  updateHelp(value) {
    return this.pagesCollection.doc('help').set( value);
  }
}
