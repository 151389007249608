import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-single-alert',
  templateUrl: './single-alert.component.html',
  styleUrls: ['./single-alert.component.scss']
})
export class SingleAlertComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
