import { Component, OnInit, Input } from '@angular/core';
import { ManageDetailsService } from '../../manage-details.service';

@Component({
  selector: 'app-verification-status',
  templateUrl: './verification-status.component.html',
  styleUrls: ['./verification-status.component.scss']
})
export class VerificationStatusComponent implements OnInit {
  @Input() styles: any;
  @Input() type: string;

  certificates: any;
  maincert: any;

  files = [];
  certreq = [];
  verified = [];

  constructor(private manageDetailsService: ManageDetailsService) {
    
  }

  ngOnInit() {
    // Grab the certificates uploaded for the class
    this.manageDetailsService.getCertificates(this.type)
      .subscribe(
        certificates => {
          // Uploaded certificates
          this.certificates = certificates;

          // Build a list of styles that require certificates
          this.files = this.certificates.filter(x => x.file);

          this.maincert = this.certificates.filter(x => x.name == this.type);

          // Build a list of verified certificates
          this.verified = this.certificates.filter(x => x.verified == true);

          console.log(this.files, this.certificates, this.verified);
        },
        error => console.log(error)
      );

    
  }

}
