import { Component, OnInit } from '@angular/core';
import {ClassService} from '../../class.service';

@Component({
  selector: 'app-success',
  templateUrl: './post-class-success.component.html',
  styleUrls: ['./post-class-success.component.scss']
})
export class PostClassSuccessComponent implements OnInit {

  docID: string;
  constructor(private dataService: ClassService) { }

  ngOnInit() {
    this.dataService.getSavedDoc()
      .subscribe(id => this.docID = id);
  }

}
