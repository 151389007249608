import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { SettingsService } from "./settings.service";

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  settings: any;

  settingsForm = this.fb.group({
    t1: [''],
    t2: [''],
    t3: [''],
    t4: ['']
  });

  constructor(
    public fb: FormBuilder,
    public settingsService: SettingsService,
  ) { }

  ngOnInit() {
  }



  save() {
  }
}
