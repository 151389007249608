import { Component, OnInit } from '@angular/core';

import { SettingsService } from '../../settings/settings.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {
  public page: any;

  constructor(private settingsService: SettingsService) {
    this.settingsService.getPrivacySettings()
        .subscribe(
          page => {
            this.page = page;
          },
          errors => console.log(errors)
        );
  }

  ngOnInit() {
  }

}
