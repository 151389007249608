import { Component, OnInit, Input } from '@angular/core';
import { ManageDetailsService } from '../../manage/manage-details.service';
import { ClassService } from '../../class.service';
import { SettingsService } from '../../settings/settings.service';


@Component({
  selector: 'app-single-alert-submission',
  templateUrl: './single-alert-submission.component.html',
  styleUrls: ['./single-alert-submission.component.scss']
})
export class SingleAlertSubmissionComponent implements OnInit {
  @Input() alert;
  @Input() classObject;
  @Input() submission;

  profile: any;
  singleclass: any;
  pSlug: any;

  constructor(
    private manageDetailsService: ManageDetailsService,
    private classService: ClassService,
    private settingsService: SettingsService
  ) { }

  ngOnInit() {
    if(this.alert) {
      this.loadAlert();
    }
    if(this.submission && this.classObject) {
      this.singleclass = this.classObject;
      this.getProfile(this.submission)
    }
  }

  getProfile(id) {
    this.manageDetailsService.getProfileDetails(id)
      .subscribe(
        profile => {
          this.profile = profile;
          this.pSlug = this.settingsService.buildProfileSlug(this.profile);
        },
        errors => console.log(errors)
      );
  }

  loadAlert() {
    this.getProfile(this.alert.profileId);

    this.classService.getClass(this.alert.classId)
      .subscribe(
        singleclass => this.singleclass = singleclass,
        errors => console.log(errors)
      );
  }

  cover() {
    this.classService.acceptCover(this.singleclass.id, this.submission);
  }

}
