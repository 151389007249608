import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { FormBuilder, Validators } from '@angular/forms';

// FS
import { AngularFirestore } from '@angular/fire/firestore';

import { AuthService } from '../core/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  error: string;
  
  form = this.fb.group({
    email: ['', [Validators.required]],
    password: ['', Validators.required]
  });

  constructor(
    public router: Router,
    public afs: AngularFirestore,
    public fb: FormBuilder,
    private authService: AuthService
    ) { }

  ngOnInit() {
    
  }

  signInEmail() {
    if(this.form.valid) {
      this.authService.signInWithEmail(this.form.value)
        .catch(
          (err) => this.error = err
        );
    }
  }

  signInFB() {
    this.authService.signInWithFacebook()
      .catch(
        (err) => this.error = err
      );
  }

  signInGo() {
    this.authService.signInWithGoogle()
      .catch(
        (err) => this.error = err
      );
  }
  

}
