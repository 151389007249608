import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { ManageDetailsService } from '../manage/manage-details.service';

import { AuthService } from '../core/auth.service';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss']
})
export class AlertsComponent implements OnInit {
  alerts: Object;

  constructor(
    private renderer: Renderer2,
    private manageDetailsService: ManageDetailsService,
    private auth: AuthService
  ) { }

  ngOnInit() {
    this.renderer.addClass(document.body, "profile-page");
    this.renderer.addClass(document.body, "alert-page");
   
    this.auth.getUserId()
      .subscribe(
        user => {
          if(user) {
            this.manageDetailsService.getAlerts(user.uid)
              .subscribe(
                alerts => {
                  this.alerts = alerts;
                  console.log(alerts);
                },
                errors => console.log(errors)
              );
          }
        }
      );
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, "profile-page");
    this.renderer.removeClass(document.body, "alert-page");
  }

}
