import { Component, OnInit } from '@angular/core';

import { SettingsService } from '../settings.service';

@Component({
  selector: 'app-certs',
  templateUrl: './certs.component.html',
  styleUrls: ['./certs.component.scss']
})
export class CertsComponent implements OnInit {
  public certs: Object;

  constructor(private settingsService: SettingsService) { }

  ngOnInit() {
    // Retrieve initial classes
    this.getCerts();
  }

  getCerts() {
    this.settingsService.getCertificates()
      .subscribe(
        certs => { this.certs = certs; },
        errors => console.log(errors)
      )
  }

}
