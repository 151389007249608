import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { HelpService } from "./help.service";

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {
  help: any;

  helpForm = this.fb.group({
    content: [''],
  });

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '25rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no'
  };

  constructor(
    public fb: FormBuilder,
    public hService: HelpService,
  ) { }

  ngOnInit() {
    this.getHelpContent();
  }

  getHelpContent() {
    this.help = this.hService.getHelp();

    this.help.subscribe(
      help => this.helpForm.patchValue(help),
      errors => console.log(errors)
    )
  }

  save() {
    this.hService.updateHelp(this.helpForm.value);
  }
}
