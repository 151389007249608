import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { SettingsService } from '../../settings/settings.service';
import { ManageDetailsService } from '../manage-details.service';
import { UploadService } from '../../upload.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ManageProfileComponent implements OnInit {
  public selectedClass: any;
  public addClass: boolean;
  public manage: boolean;
  public classes: Array<any>;
  public classSettings: any;
  public certificates = [];
  public levels = [];
  public uploadCertificates = false;
  public editingClass = false;
  public addedAllClassesError = false;
  public uploading = false;

  public selectedLevels = [];
  public selectedStyles = [];
  public selectedSpecialities = [];

  id: number;

  certificateFiles = [];

  constructor(
    private settingsService: SettingsService,
    private manageDetailsService: ManageDetailsService,
    private uploadService: UploadService,
    private router: Router
    ) { 
    this.selectedClass = Object;
    this.selectedClass.type = "Select A Class";

    this.router.events.subscribe((event)  => {
      if(event instanceof NavigationEnd) {
          var re = /manage/gi;
          var str = event.url;
          // Search string for manage to change text
          if (str.search(re) == -1 ) { 
            this.manage = false;
          } else { 
            this.manage = true;
          } 
      }
    });
  }

  ngOnInit() {
    this.getClassSettings();
    this.getUserClasses();
  }

  hasFile(name) {
    // Find and fill class search based on type
    return this.certificateFiles.find(x => x.name == name);
  }

  isCheckedLevel(name) {
    // Find and fill class search based on type
    return this.selectedLevels.find(x => x.name == name);
  }

  isCheckedStyle(name) {
    // Find and fill class search based on type
    return this.selectedStyles.find(x => x.name == name);
  }

  isCheckedSpeciality(name) {
    // Find and fill class search based on type
    return this.selectedSpecialities.find(x => x.name == name);
  }

  reset() {
    // Reset checkboxes array
    this.selectedClass = [];
    this.selectedLevels = [];
    this.selectedStyles = [];
    this.selectedSpecialities = [];

    // Hide add class
    this.addClass = false;

    // Hide edit class
    this.editingClass = false;
  }

  hideUpload() {
    // Display upload form
    this.uploadCertificates = false;
  }

  editClass(userClass) {
    // Reset
    this.reset();

    this.hideUpload();

    // Display Form
    this.editingClass = true;

    // Fill in checkboxes
    this.selectedLevels = userClass.levels;
    this.selectedStyles = userClass.styles;
    this.selectedSpecialities = userClass.specialities;

    // Find and fill class search based on type
    this.selectedClass = this.classSettings.find(x => x.type == userClass.type);
  }

  getUserClasses() {
    // Find authenticated user
    this.manageDetailsService.getAuth().subscribe(user => {
      if(user) {
        // When a authenticated user is found grab their details
        this.manageDetailsService.getClassesDetails(user.uid)
          .subscribe(
            classes => this.classes = classes,
            error => console.log(error)
          )
      }
    });
  }

  getClassSettings() {
    this.settingsService.getClasses()
      .subscribe(
        classes => this.classSettings = classes,
        errors => console.log(errors)
      )
  }

  fillDetails(item) {
    // Reset certs
    this.certificates = [];

    // Find and fill class
    this.selectedClass = this.classSettings.find(x => x.id == item);
  }

  /* 
    This function builds a record of the class but I'm sure there is a better way
    using reactive forms. Perhaps with certificate requirements it is the only way 
  */
  buildClass(checked, label, type, cert?) {
    switch(type) { 
      case "level": { 
        // Complete main object functions
        this.selectedLevels = this.addOrRemoveFromObject(this.selectedLevels, label, checked, false);

        break; 
      } 
      case "style": { 
        // Complete main object functions
        this.selectedStyles = this.addOrRemoveFromObject(this.selectedStyles, label, checked, cert);

        //statements; 
        break; 
      } 
      case "specialty": { 
        // Complete main object functions
        this.selectedSpecialities = this.addOrRemoveFromObject(this.selectedSpecialities, label, checked, cert);
        
        //statements; 
        break; 
      } 
      default: { 
         //statements;
         break; 
      } 
   }
  }

  addOrRemoveFromObject(array, label, checked, cert = false) {
    if(checked ==  true) {
      if(cert ==  true) {
        // Add to array
        array.push({ name: label, certrequired: cert })
      } else {
        // Add to array
        array.push({ name: label, certrequired: cert})
      }
    } else {
      if(cert == true && this.editingClass == true) {
        // Delete certificate requirement if in edit mode
        this.manageDetailsService.deleteCert(label, this.selectedClass.type);
      }
      // Filter out type
      array = array.filter(type => type.name != label);
    }

    return array;
  }

  add() {
    if(this.classes.length != this.classSettings.length) {
      // Reset all arrays
      this.reset();
      this.hideUpload();

      // Display Form
      this.addClass = true;
    } else {
      // Display error
      this.addedAllClassesError = true;

      // Remove after a few seconds
      setTimeout(() => {
        this.addedAllClassesError = false;
      }, 5000);
    }
  }

  generateRequiredCertificates(type, styles) {
    return new Promise((resolve, reject) => {

      let certificatesRequired = styles.filter(x => styles.certrequired == true);

      // This adds certification for the main class if it is a first save
      if(this.editingClass == false) {
        // Add to certification list
        this.manageDetailsService.addCert(this.selectedClass.type, this.selectedClass.type);
      }

      for (let index = 0; index < styles.length; index++) {
        const element = styles[index];

        // Add to certification list
        this.manageDetailsService.addCert(element.name, this.selectedClass.type);
      }
      
      resolve();
    });
  }

  save() {
    // Run through and update certificates required
    this.generateRequiredCertificates(this.selectedClass.type, this.selectedStyles).then(() => {
      // Generate upload forms
      this.uploadFormShow(this.selectedStyles);
    });

    this.manageDetailsService.saveClass(this.selectedClass.type, this.selectedLevels, this.selectedStyles, this.selectedSpecialities)
  }

  async upload() {
    this.uploading = true;

    // Upload insurance and first aid documents
    for (let index = 0; index < this.certificateFiles.length; index++) {
      const element = this.certificateFiles[index];
      
      // Upload certificate and store reference
      const uploadRef = <string>await this.uploadService.uploadFile(element.fileLoc);

      console.log(element);
      
      // Save certificate and name
      this.manageDetailsService.saveCertFile(element.name, uploadRef, this.selectedClass.type);
    }
    // Put a delay on so the user can see tick
    setTimeout(() => {
      this.uploading = false;

      // Clear and reset
      this.hideUpload();
      this.reset();
    }, 2000);
  }

  uploadFormShow(styles, type?) {
    // Hide edit
    this.editingClass = false;

    // Hide add class
    this.addClass = false;

    // Set type when just showing upload form
    if(type) {
      this.selectedClass.type = type;
    }
    
    // Load list required certificates
    this.manageDetailsService.getCertificates(this.selectedClass.type)
      .subscribe(
        certificates => { 
          this.certificates = certificates;
        },
        errors => console.log(errors)
      );
    
    // Display upload form
    this.uploadCertificates = true;
  }

  saveSkipUpload() {
    this.reset();
    this.hideUpload();
  }

  addFile(file, name, i) {
    console.log(name);

    // Build an array of files for upload
    let file4Upload = {
      fileLoc: file,
      name: name
    }

    // Add to array for upload
    this.certificateFiles.push(file4Upload);
  }

  ngOnDestroy() {
  }

}
