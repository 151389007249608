import { Component, OnInit } from '@angular/core';
import {TypeMetadata} from '../../models/post-class-form-data';
import {ClassService} from '../../class.service';
import {FormBuilder, Validators} from '@angular/forms';
import {SettingsService} from '../../settings/settings.service';

@Component({
  selector: 'app-type-metadata',
  templateUrl: './type-metadata.component.html',
  styleUrls: ['./type-metadata.component.scss']
})
export class TypeMetadataComponent implements OnInit {
  typeMetadata: TypeMetadata;
  userClasses: any[];
  selectedClass: any[];

  form = this.formBuilder.group({
    type: ['', Validators.required],
    style: ['', Validators.required],
    level: ['', Validators.required],
    speciality: ['', Validators.required]
  });

  constructor(private dataService: ClassService,
              private formBuilder: FormBuilder,
              private settingsService: SettingsService) { }

  ngOnInit() {
    this.settingsService.getClasses().subscribe( classes => {
      this.userClasses = classes;
      const type = this.form.controls['type'].value;
      if ( type !== '') {
        this.setSelectedClass(type);
      }
    });

    this.initializeForm();
  }

  setSelectedClass(classType) {
       this.selectedClass = this.userClasses.find( c =>  c.type === classType );
  }

  saveData() {
    if(this.form.valid) {
      this.dataService.setTypeMetadata(this.form.value, this.selectedClass);
    }
  }

  initializeForm() {
    this.form.patchValue(this.dataService.getTypeMetadata());
  }

}
