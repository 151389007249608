import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PostCodeQueryService {
  private readonly API_URL = 'https://api.postcodes.io/postcodes/';

  constructor(private httpClient: HttpClient) { }

  getLocationDataFromPostCode(postCode) {
    return this.httpClient.get(this.API_URL + postCode).pipe(map((res: any) => {
      return {
        latitude: res.result.latitude,
        longitude: res.result.longitude
      };
    }));
  }
}
