import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { SettingsService } from '../../settings.service';

@Component({
  selector: 'app-labels',
  templateUrl: './labels.component.html',
  styleUrls: ['./labels.component.scss']
})
export class LabelsPagesComponent implements OnInit {
  form = this.fb.group({
    postClass: ['', [Validators.required]],
    findClass: ['', [Validators.required] ]
  });

  constructor(
    public fb: FormBuilder,
    private settingsService: SettingsService
    ) { }

  ngOnInit() {
    this.settingsService.getLabelsSettings()
      .subscribe(
        labels => this.form.patchValue(labels),
        errors => console.log(errors)
      )
  }

  save() {
    if(this.form.valid) {
      this.settingsService.saveLabelsSettings(this.form.value);
    }
  }

}
