import { Component, OnInit } from '@angular/core';

import { SettingsService } from '../settings/settings.service';
import { AuthService } from '../core/auth.service';
import { map, take } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  pSlug: any;
  isUser: boolean;
  isAdmin: boolean;
  profile: any;
  labels: any;
  public menuOpen = false;
  public submenu = false;

  constructor(
    private settingsService: SettingsService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    // Get users profile and gnerate a slug
    this.settingsService.getUserId()
      .subscribe(
        user => {
          if(user) {
            this.isUser = true;
            
            // Check if user is a core admin
            this.authService.checkForAdmin(user.email)
              .then(docSnapshot => {
                if (docSnapshot.exists) {
                  this.isAdmin = true;
                }
                else {
                  this.isAdmin = false;
                }
              });

            this.settingsService.profileSlug(user.uid)
              .subscribe(
                profile => {
                  if(profile) {
                    this.profile = profile;
                    this.pSlug = this.settingsService.buildProfileSlug(profile)
                  }
                }
              );
          }
          else {
            this.isUser = false;
          }
        }
      );
      this.settingsService.getLabelsSettings()
        .subscribe(
          labels => {
            this.labels = labels;
          },
          errors => console.log(errors)
        );
  }

  openMenu() {
    if(this.menuOpen == false) {
      this.menuOpen = true
    } else {
      this.menuOpen = false
    }
  }

  openSubMenu() {
    console.log("He");
    if(this.submenu == false) {
      this.submenu = true
    } else {
      this.submenu = false
    }
  }

  signout() {
    this.pSlug = null;
    this.isUser = null;
    this.isAdmin = null;
    this.profile = null;
    this.authService.endSession();
  }

}
